define("subscriptionclient/components/report-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    RemoveOption: {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            display: false,
            fontSize: 10
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            display: false
          },
          gridLines: {
            display: false
          }
        }]
      },
      elements: {
        point: {
          radius: 0
        }
      },
      hover: {
        mode: 'index',
        intersect: false
      },
      tooltips: {
        mode: 'index',
        intersect: false
      }
    },
    RemoveOptionmain: {
      legend: {
        display: true
      },
      scales: {
        xAxes: [{
          ticks: {
            display: true,
            fontSize: 10,
            color: 'red'
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            display: true,
            fontSize: 14,
            color: 'red'
          },
          gridLines: {
            display: true,
            color: '#f2f2f2'
          }
        }]
      },
      elements: {
        point: {
          radius: 0
        }
      },
      hover: {
        mode: 'index',
        intersect: false
      },
      tooltips: {
        mode: 'index',
        intersect: false
      }
    },
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: '#ffffff',
        tension: 0.4
      }]
    },
    data1: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: '#2078ff',
        tension: 0
      }, {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: '#5b4789',
        tension: 0
      }]
    },
    TotalDataPresent: false,
    GraphNumber: 1,
    SideReportHeading: 'Revenue Report',
    SideReportMonth: [],
    SideReportYear: [],
    SideReportPreYear: [],
    ChangeInReport: [],
    ReportBannerList: [],
    GraphData: function GraphData(Data, GraphNumber, Year) {
      var reportData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      for (var i = 0; i < Data.length; i++) {
        var InsertData = 0;

        if (GraphNumber == 1) {
          InsertData = Data[i]['Total'];
        } else if (GraphNumber == 2) {
          InsertData = Data[i]['Recurring'];
        } else if (GraphNumber == 3) {
          InsertData = Data[i]['Upgrade'];
        } else if (GraphNumber == 4) {
          InsertData = Data[i]['New'];
        } else if (GraphNumber == 5) {
          InsertData = Data[i]['Churn'];
        } else if (GraphNumber == 6) {
          InsertData = Data[i]['Downgrade'];
        } else if (GraphNumber == 7) {
          InsertData = Data[i]['ARR'];
        } else if (GraphNumber == 8) {
          InsertData = Data[i]['MRR'];
        } else if (GraphNumber == 9) {
          InsertData = Data[i]['ChurnCount'];
        } else if (GraphNumber == 10) {
          InsertData = Data[i]['NewCount'];
        } else if (GraphNumber == 11) {
          InsertData = Data[i]['TotalCustomerCount'];
        }

        reportData[i] = InsertData;

        if (i == Data.length - 1) {
          if (Year == 1) {
            this.set('SideReportYear', reportData);
            var Data1 = this.get('data1');
            Data1['datasets'][0]['data'] = reportData;
            this.set('data1', null);
            this.set('data1', Data1);
          } else {
            this.set('SideReportPreYear', reportData);
            var Data1 = this.get('data1');
            Data1['datasets'][1]['data'] = reportData;
            this.set('data1', null);
            this.set('data1', Data1);
          }
        }
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      var FullData = this.get('reportdata');

      if (FullData.hasOwnProperty('Year')) {
        if (FullData['Year'].hasOwnProperty('MonthData')) {
          var reportData = this.get('reportdata.Year.MonthData');
        } else {
          var reportData = [];
        }
      } else {
        var reportData = [];
      }

      if (FullData.hasOwnProperty('PreYear')) {
        if (FullData['PreYear'].hasOwnProperty('MonthData')) {
          var reportDataPre = this.get('reportdata.PreYear.MonthData');
        } else {
          var reportDataPre = [];
        }
      } else {
        var reportDataPre = [];
      }

      this.set('data1.labels', this.get('reportdata.months'));
      var months = this.get('reportdata.months');
      this.set('SideReportMonth', months);
      var reportDataYear = this.get('reportdata.Year.Year');
      var reportDataPreYear = this.get('reportdata.PreYear.Year');
      this.set('data.labels', months);
      var reportDataExtra = this.get('reportdata.Year');
      var reportDataPreExtra = this.get('reportdata.PreYear');
      this.set('data.datasets.0.data', reportData);
      this.set('data1.datasets.0.data', reportData);
      this.set('data1.datasets.0.label', reportDataYear);
      this.set('SideReportYear', reportData);
      this.set('data1.datasets.1.data', reportDataPre);
      this.set('data1.datasets.1.label', reportDataPreYear);
      this.set('SideReportPreYear', reportDataPre);
      var ChangeInReport = [];

      if (reportData.length > 0) {
        for (var i = 0; i < reportData.length; i++) {
          var Diff = reportData[i] - reportDataPre[i];

          if (reportDataPre[i] > 0) {
            var Diffpercentage = (Diff * 100 / reportDataPre[i]).toFixed(2);
          } else {
            var Diffpercentage = (Diff * 100).toFixed(2);
          }

          ChangeInReport.push(Diffpercentage);

          if (i + 1 == reportData.length) {
            this.set('ChangeInReport', ChangeInReport);
          }
        }

        this.set('TotalDataPresent', true);
      } else {
        this.set('TotalDataPresent', false);
      }

      var ReportBannerList = [];
      var GraphNumber = 0;

      if (reportDataExtra.hasOwnProperty('Total')) {
        if (GraphNumber == 0) {
          GraphNumber = 1;
        }
      }

      if (reportDataExtra.hasOwnProperty('Recurring')) {
        var RecurringDiff = reportDataExtra['Recurring'] - reportDataPreExtra['Recurring'];

        if (reportDataPreExtra['Recurring'] > 0) {
          var RecurringPercentage = (RecurringDiff * 100 / reportDataPreExtra['Recurring']).toFixed(2);
        } else {
          var RecurringPercentage = (RecurringDiff * 100).toFixed(2);
        }

        var RecurringDict = {
          'text': 'RECURRING REVENUE',
          'amount': reportDataExtra['Recurring'],
          'percentage': RecurringPercentage
        };
        ReportBannerList.push(RecurringDict);

        if (GraphNumber == 0) {
          GraphNumber = 2;
        }
      }

      if (reportDataExtra.hasOwnProperty('Upgrade')) {
        var UpgradeDiff = reportDataExtra['Upgrade'] - reportDataPreExtra['Upgrade'];

        if (reportDataPreExtra['Upgrade'] > 0) {
          var UpgradePercentage = (UpgradeDiff * 100 / reportDataPreExtra['Upgrade']).toFixed(2);
        } else {
          var UpgradePercentage = (UpgradeDiff * 100).toFixed(2);
        }

        var UpgradeDict = {
          'text': 'UPGRADE REVENUE',
          'amount': reportDataExtra['Upgrade'],
          'percentage': UpgradePercentage
        };
        ReportBannerList.push(UpgradeDict);

        if (GraphNumber == 0) {
          GraphNumber = 3;
        }
      }

      if (reportDataExtra.hasOwnProperty('New')) {
        var NewDiff = reportDataExtra['New'] - reportDataPreExtra['New'];

        if (reportDataPreExtra['New'] > 0) {
          var NewPercentage = (NewDiff * 100 / reportDataPreExtra['New']).toFixed(2);
        } else {
          var NewPercentage = (NewDiff * 100).toFixed(2);
        }

        var NewDict = {
          'text': 'NEW CUSTOMER REVENUE',
          'amount': reportDataExtra['New'],
          'percentage': NewPercentage
        };
        ReportBannerList.push(NewDict);

        if (GraphNumber == 0) {
          GraphNumber = 4;
        }
      }

      if (reportDataExtra.hasOwnProperty('Churn')) {
        var ChurnDiff = reportDataExtra['Churn'] - reportDataPreExtra['Churn'];

        if (reportDataPreExtra['Churn'] > 0) {
          var ChurnPercentage = (ChurnDiff * 100 / reportDataPreExtra['Churn']).toFixed(2);
        } else {
          var ChurnPercentage = (ChurnDiff * 100).toFixed(2);
        }

        var ChurnDict = {
          'text': 'CHURN CUSTOMER LOSS',
          'amount': reportDataExtra['Churn'],
          'percentage': ChurnPercentage
        };
        ReportBannerList.push(ChurnDict);

        if (GraphNumber == 0) {
          GraphNumber = 5;
        }
      }

      if (reportDataExtra.hasOwnProperty('ARR')) {
        var ARRDiff = reportDataExtra['ARR'] - reportDataPreExtra['ARR'];

        if (reportDataPreExtra['ARR'] > 0) {
          var ARRPercentage = (ARRDiff * 100 / reportDataPreExtra['ARR']).toFixed(2);
        } else {
          var ARRPercentage = (ARRDiff * 100).toFixed(2);
        }

        var ARRDict = {
          'text': 'ANNUAL RECURRING REVENUE',
          'amount': reportDataExtra['ARR'],
          'percentage': ARRPercentage
        };
        ReportBannerList.push(ARRDict);

        if (GraphNumber == 0) {
          GraphNumber = 7;
        }
      }

      this.set('ReportBannerList', ReportBannerList); // Data set In Graph

      var MonthreportData = this.get('reportdata.Month');
      var MonthreportDataPre = this.get('reportdata.PreMonth');
      var reportData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (MonthreportData.length > 0) {
        this.GraphData(MonthreportData, GraphNumber, 1);
      } else {
        this.set('SideReportYear', reportData);
        var Data1 = this.get('data1');
        Data1['datasets'][0]['data'] = reportData;
        this.set('data1', null);
        this.set('data1', Data1);
      }

      var reportDataPre = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (MonthreportDataPre.length > 0) {
        this.GraphData(MonthreportDataPre, GraphNumber, 0);
      } else {
        this.set('SideReportPreYear', reportDataPre);
        var Data1 = this.get('data1');
        Data1['datasets'][1]['data'] = reportDataPre;
        this.set('data1', null);
        this.set('data1', Data1);
      }

      var ChangeInReport = [];

      for (var i = 0; i < reportData.length; i++) {
        var Diff = reportData[i] - reportDataPre[i];

        if (reportDataPre[i] > 0) {
          var Diffpercentage = (Diff * 100 / reportDataPre[i]).toFixed(2);
        } else {
          var Diffpercentage = (Diff * 100).toFixed(2);
        }

        ChangeInReport.push(Diffpercentage);

        if (i + 1 == reportData.length) {
          this.set('ChangeInReport', ChangeInReport);
        }
      }

      if (GraphNumber == 1) {
        this.set('SideReportHeading', 'Total Revenue');
      } else if (GraphNumber == 2) {
        this.set('SideReportHeading', 'Recurring Revenue');
      } else if (GraphNumber == 3) {
        this.set('SideReportHeading', 'Upgrade Revenue');
      } else if (GraphNumber == 4) {
        this.set('SideReportHeading', 'New Customer Revenue');
      } else if (GraphNumber == 5) {
        this.set('SideReportHeading', 'Churn Loss');
      } else if (GraphNumber == 6) {
        this.set('SideReportHeading', 'Downgrade');
      } else if (GraphNumber == 7) {
        this.set('SideReportHeading', 'ARR');
      }
    },
    actions: {
      NextArrow: function NextArrow(to) {
        // 1 - total Revenu, 2 - Recurring, 3 - upgrade, 4- New customer, 5- Churn, 6 - Downgrade, 7 - ARR ,8 - MRR,9 - Churn Count,10 - New Customer Count,11 - Total Customer Count
        var GraphNumber = this.get('GraphNumber');
        var reportDataExtra = this.get('reportdata.Year');
        var months = this.get('reportdata.months');
        var graphs = this.get('reportdata.Graphs');
        var GraphDataPresent = [];

        if (reportDataExtra.hasOwnProperty('Total')) {
          GraphDataPresent.push(1);
        }

        if (reportDataExtra.hasOwnProperty('Recurring')) {
          GraphDataPresent.push(2);
        }

        if (reportDataExtra.hasOwnProperty('Upgrade')) {
          GraphDataPresent.push(3);
        }

        if (reportDataExtra.hasOwnProperty('New')) {
          GraphDataPresent.push(4);
        }

        if (reportDataExtra.hasOwnProperty('Churn')) {
          GraphDataPresent.push(5);
        }

        if (reportDataExtra.hasOwnProperty('Downgrade')) {
          GraphDataPresent.push(6);
        }

        if (reportDataExtra.hasOwnProperty('ARR')) {
          GraphDataPresent.push(7);
        }

        if (graphs.includes('mrr')) {
          GraphDataPresent.push(8);
        }

        if (graphs.includes('churncount')) {
          GraphDataPresent.push(9);
        }

        if (graphs.includes('newcount')) {
          GraphDataPresent.push(10);
        }

        if (graphs.includes('totalcount')) {
          GraphDataPresent.push(11);
        }

        if (to == 1) {
          GraphNumber = parseInt(GraphNumber) + 1;
        } else {
          GraphNumber = parseInt(GraphNumber) - 1;
        }

        if (GraphNumber < 1) {
          GraphNumber = 11;
        }

        if (GraphNumber > 11) {
          GraphNumber = 1;
        }

        if (!GraphDataPresent.includes(GraphNumber)) {
          if (GraphDataPresent.length > 1) {
            GraphDataPresent.sort(function (a, b) {
              return a - b;
            });

            for (var k = 0; k < GraphDataPresent.length; k++) {
              if (GraphDataPresent[k] > GraphNumber) {
                GraphNumber = GraphDataPresent[k];
                break;
              }

              if (k + 1 == GraphDataPresent.length) {
                GraphNumber = GraphDataPresent[0];
              }
            }
          } else {
            GraphNumber = GraphDataPresent[0];
          }
        }

        this.set('GraphNumber', GraphNumber);
        var MonthreportData = this.get('reportdata.Month');
        var MonthreportDataPre = this.get('reportdata.PreMonth');
        var reportData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        if (MonthreportData.length > 0) {
          this.GraphData(MonthreportData, GraphNumber, 1);
        } else {
          this.set('SideReportYear', reportData);
          var Data1 = this.get('data1');
          Data1['datasets'][0]['data'] = reportData;
          this.set('data1', null);
          this.set('data1', Data1);
        }

        var reportDataPre = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        if (MonthreportDataPre.length > 0) {
          this.GraphData(MonthreportDataPre, GraphNumber, 0);
        } else {
          this.set('SideReportPreYear', reportDataPre);
          var Data1 = this.get('data1');
          Data1['datasets'][1]['data'] = reportDataPre;
          this.set('data1', null);
          this.set('data1', Data1);
        }

        var ChangeInReport = [];

        for (var i = 0; i < reportData.length; i++) {
          var Diff = reportData[i] - reportDataPre[i];

          if (reportDataPre[i] > 0) {
            var Diffpercentage = (Diff * 100 / reportDataPre[i]).toFixed(2);
          } else {
            var Diffpercentage = (Diff * 100).toFixed(2);
          }

          ChangeInReport.push(Diffpercentage);

          if (i + 1 == reportData.length) {
            this.set('ChangeInReport', ChangeInReport);
          }
        }

        if (GraphNumber == 1) {
          this.set('SideReportHeading', 'Total Revenue');
        } else if (GraphNumber == 2) {
          this.set('SideReportHeading', 'Recurring Revenue');
        } else if (GraphNumber == 3) {
          this.set('SideReportHeading', 'Upgrade Revenue');
        } else if (GraphNumber == 4) {
          this.set('SideReportHeading', 'New Customer Revenue');
        } else if (GraphNumber == 5) {
          this.set('SideReportHeading', 'Churn Loss');
        } else if (GraphNumber == 6) {
          this.set('SideReportHeading', 'Downgrade Revenue');
        } else if (GraphNumber == 7) {
          this.set('SideReportHeading', 'ARR');
        } else if (GraphNumber == 8) {
          this.set('SideReportHeading', 'MRR');
        } else if (GraphNumber == 9) {
          this.set('SideReportHeading', 'Churn Count');
        } else if (GraphNumber == 10) {
          this.set('SideReportHeading', 'New Customer Count');
        } else if (GraphNumber == 11) {
          this.set('SideReportHeading', 'Total Customer Count');
        }
      }
    }
  });

  _exports.default = _default;
});